import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Badge,
  Stack,
  Avatar,
  ButtonGroup,
} from '@chakra-ui/react';
import ChannelDetails from './ChannelDetails';
import ChannelDelete from './ChannelDelete';

const ChannelsTable = ({ channelsData, refresh }) => {
  const { channels } = channelsData;

  return (
    <Table variant="unstiled" size="sm">
      <Thead>
        <Tr>
          <Th>&nbsp;</Th>
          <Th>Title</Th>
          <Th>&nbsp;</Th>
        </Tr>
      </Thead>
      <Tbody>
        {channels.map(c => (
          <Tr key={c.channel_id}>
            <Td width="50px">
              <Avatar src={c.logo} />
            </Td>
            <Td>
              <Stack spacing={2}>
                <Text fontSize="lg" noOfLines={1} h="1.25em">
                  {c.title}
                </Text>
                <Stack direction="row" spacing={2}>
                  <Badge colorScheme={c.live ? 'red' : 'green'}>
                    {c.live ? 'Live' : 'VOD'}
                  </Badge>
                  {!c.live && (
                    <Badge
                      colorScheme={c.tmdb.type === 'movie' ? 'orange' : 'blue'}
                    >
                      {c.tmdb.type === 'movie' ? 'Movie' : 'TV'}
                    </Badge>
                  )}
                  <Badge colorScheme="yellow">
                    Category: {c.category.name}
                  </Badge>
                  {c.bouquets.map(b => (
                    <Badge key={b.bouquet_id}>{b.name}</Badge>
                  ))}
                </Stack>
              </Stack>
            </Td>
            <Td>
              <ButtonGroup isAttached>
                <ChannelDetails
                  inputChannel={c}
                  refresh={refresh}
                  isCreate={false}
                />
                <ChannelDelete channel={c} refresh={refresh} />
              </ButtonGroup>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ChannelsTable;
