import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logout as apiLogout } from '../api';

import userState from '../userState';

export default function Logout() {
  const setUser = useRecoilState(userState)[1]
  useEffect(_ => {
    apiLogout().then(setUser)
  }, [setUser])
  return <Navigate to="/" />;
}
