import {
  Badge
} from '@chakra-ui/react'

const UserTypeBadge = ({ user }) => {
  if (!user) return <Badge colorScheme='red'>deleted</Badge>
  let username = ''
  if (user.username) username = <><strong>{user.username}</strong><br /></>
  let badge = ''
  if (user.user_type === 0) badge = <Badge colorScheme='green'>user</Badge>
  if (user.user_type === 1) badge = <Badge colorScheme='yellow'>reseller</Badge>
  if (user.user_type === 2) badge = <Badge colorScheme='orange'>reseller (w/sub)</Badge>
  if (user.user_type === 3) badge = <Badge colorScheme='purple'>admin</Badge>
  return <>
    {username}
    {badge}
  </>
}

export default UserTypeBadge
