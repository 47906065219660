import { useState } from 'react';
import {
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  Box,
  FormControl,
  InputRightElement,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useRecoilState } from 'recoil';

import { login as apiLogin } from '../api';
import userState from '../userState';

const Login = _ => {
  const setUser = useRecoilState(userState)[1];
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleShowClick = _ => setShowPassword(!showPassword);
  const bg = useColorModeValue('white', 'gray.800');

  const handleLogin = async e => {
    e.preventDefault();
    const loginResult = await apiLogin(username, password);
    setUser(loginResult || {});
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Stack
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Heading>Stream Engine</Heading>
        <Box>
          <form onSubmit={handleLogin}>
            <Stack spacing={4} p="1rem" boxShadow="md" bg={bg}>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaUserAlt />}
                  />
                  <Input
                    type="username"
                    placeholder="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaLock />}
                  />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={6}
                type="submit"
                variant="solid"
                width="full"
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
