import { useRef } from 'react'

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Stack,
  Text,
  Badge,
  useToast
} from '@chakra-ui/react'

import { FaTrashAlt } from 'react-icons/fa'
import { deleteUser } from '../api'

const UserDelete = ({ user, refresh }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const doDelete = async _ => {
    const result = await deleteUser(user)
    if (!result) {
      toast({
        title: 'Delete failed',
        description: 'An error has occurred!',
        status: 'error'
      })
      return
    }
    toast({
      title: 'Deleted!',
      description: `${user.username} has been deleted.`,
      status: 'success'
    })
    refresh()
  }

  return <>
  <Button size='sm' colorScheme='red' onClick={onOpen}><FaTrashAlt /></Button>
  <AlertDialog
    motionPreset="slideInBottom"
    leastDestructiveRef={cancelRef}
    onClose={onClose}
    isOpen={isOpen}
    isCentered
    size='2xl'
  >
    <AlertDialogOverlay />

    <AlertDialogContent>
      <AlertDialogHeader>Delete User?</AlertDialogHeader>
      <AlertDialogCloseButton />
      <AlertDialogBody>
        <Stack direction='row' spacing={5} align='center'>
          <Text>
            Are you sure you want to delete user <Badge>#{user.user_id}</Badge>?<br />
            <strong>{user.username}</strong>
          </Text>
        </Stack>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={onClose}>
          No
        </Button>
        <Button colorScheme="red" ml={3} onClick={doDelete}>
          Yes
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
  </>
}

export default UserDelete
