import { Flex, Progress, SimpleGrid, Text, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaWallet, FaRocket, FaShoppingCart, FaChartBar } from 'react-icons/fa';

import IconBox from '../components/IconBox';
import { getMetrics } from '../api';

export default function Stats() {
  const [metrics, setMetrics] = useState(null)
  useEffect(_ => {
    const interval = setInterval(_ => getMetrics().then(setMetrics), 5E3)
    return _ => {
      clearInterval(interval)
    }
  }, [])
  return <Box>
    <Flex direction="column" w="100%" py="20px">
      <SimpleGrid gap={{ sm: '12px' }} columns={4}>
        <Flex direction="column">
          <Flex alignItems="center">
            <IconBox as={Box} h={'30px'} w={'30px'} me="6px">
              <FaWallet h={'15px'} w={'15px'} />
            </IconBox>
            <Text fontWeight="semibold">Open Channels</Text>
          </Flex>
          <Text fontWeight="bold" mb="6px" my="6px">
            {metrics?.channels || 0}
          </Text>
          <Progress
            colorScheme="teal"
            borderRadius="12px"
            h="5px"
            value={metrics?.channels || 0}
            max={metrics?.channelsMax || 1}
            isIndeterminate={metrics === null}
          />
        </Flex>
        <Flex direction="column">
          <Flex alignItems="center">
            <IconBox as={Box} h={'30px'} w={'30px'} me="6px">
              <FaRocket h={'15px'} w={'15px'} />
            </IconBox>
            <Text fontWeight="semibold">Online</Text>
          </Flex>
          <Text fontWeight="bold" mb="6px" my="6px">
            {metrics?.users || 0}
          </Text>
          <Progress
            colorScheme="teal"
            borderRadius="12px"
            h="5px"
            value={metrics?.users || 0}
            max={metrics?.usersMax || 1}
            isIndeterminate={metrics === null}
          />
        </Flex>
        <Flex direction="column">
          <Flex alignItems="center">
            <IconBox as={Box} h={'30px'} w={'30px'} me="6px">
              <FaShoppingCart h={'15px'} w={'15px'} />
            </IconBox>
            <Text fontWeight="semibold">Traffic In</Text>
          </Flex>
          <Text fontWeight="bold" mb="6px" my="6px">
            {metrics?.trafficIn || 0} Mbps
          </Text>
          <Progress
            colorScheme="teal"
            borderRadius="12px"
            h="5px"
            value={metrics?.trafficIn || 0}
            max={metrics?.trafficInMax || 1}
            isIndeterminate={metrics === null}
          />
        </Flex>
        <Flex direction="column">
          <Flex alignItems="center">
            <IconBox as={Box} h={'30px'} w={'30px'} me="6px">
              <FaChartBar h={'15px'} w={'15px'} />
            </IconBox>
            <Text fontWeight="semibold">Traffic Out</Text>
          </Flex>
          <Text fontWeight="bold" mb="6px" my="6px">
            {metrics?.trafficOut || 0} Mbps
          </Text>
          <Progress
            colorScheme="teal"
            borderRadius="12px"
            h="5px"
            value={metrics?.trafficOut || 0}
            max={metrics?.trafficOutMax || 1}
            isIndeterminate={metrics === null}
          />
        </Flex>
      </SimpleGrid>
    </Flex>
  </Box>
}
