import { useState, useEffect, useRef } from 'react'

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spacer,
  ButtonGroup,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  IconButton,
  Avatar,
  Text,
  Stack
} from '@chakra-ui/react'

import {
  FaTimes,
  FaClipboardList,
  FaTrash,
  FaArrowUp,
  FaArrowDown
} from 'react-icons/fa'

import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteItem
} from '@choc-ui/chakra-autocomplete'

import { getBouquetChannels, searchChannels, addChannelToBouquet } from '../api'

const BouquetsDetails = ({ bouquet }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [bouquetChannels, setBouquetChannels] = useState([])
  const [channelQuery, setChannelQuery] = useState('')
  const [channels, setChannels] = useState([])

  useEffect(_ => {
    getBouquetChannels(bouquet.bouquet_id).then(setBouquetChannels)
  }, [bouquet.bouquet_id])

  const openModal = _ => {
    onOpen()
  }

  const closeModal = _ => {
    onClose()
  }

  const addChannel = async channel => {
    const channelID = 1 * channel.item.value 
    await addChannelToBouquet(bouquet.bouquet_id, channelID)
    setChannelQuery('')
    getBouquetChannels(bouquet.bouquet_id).then(setBouquetChannels)
  }

  let timeout = useRef()
  const debouncedSearch = _ => {
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }
    timeout.current = setTimeout(_ => {
      if (channelQuery.length < 3) return
      searchChannels(channelQuery).then(setChannels)
    }, 500)
  }
  useEffect(debouncedSearch, [channelQuery])

  const bouquetChannelIDs = bouquetChannels.map(channel => channel.channel_id)

  return <>
    <Button onClick={openModal}
      size='sm'
      colorScheme='blue'
      leftIcon={<FaClipboardList />}
      style={{ width: 'fit-content' }}>
        Details
    </Button>
    <Modal isOpen={isOpen} onClose={closeModal} isCentered scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent style={{maxWidth:'1024px', maxHeight: '600px'}}>
        <ModalHeader>Details: {bouquet.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing='4'>
            <AutoComplete onSelectOption={addChannel}>
              <AutoCompleteInput value={channelQuery} variant='filled' placeholder='Search for channels' onChange={e => setChannelQuery(e.target.value)} />
              <AutoCompleteList>
                {
                  channels.filter(c => !bouquetChannelIDs.includes(c.channel_id)).map(channel => <AutoCompleteItem key={`option-${channel.channel_id}`} value={`${channel.channel_id}`} label={channel.title}>
                    <Avatar src={channel.logo} alt={channel.title} size='sm' />
                    <Text ml='4'>{channel.title}</Text>
                    <Spacer />
                    <Badge colorScheme={channel.live ? 'red' : (channel.tmdb.type === 'tv' ? 'blue' : 'orange')}>{channel.live ? 'live' : channel.tmdb.type}</Badge>
                  </AutoCompleteItem>)
                }
              </AutoCompleteList>
            </AutoComplete>
            <Table variant='unstyled' size='sm'>
              <Thead>
                <Tr>
                  <Th width='0'>&nbsp;</Th>
                  <Th width='0'>ID</Th>
                  <Th>Channel</Th>
                  <Th width='0'>&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  bouquetChannels.map((channel, i) => <Tr key={channel.channel_id}>
                    <Td>
                      <ButtonGroup size='xs' isAttached>
                        <IconButton icon={<FaArrowUp />} colorScheme='blue' disabled={i===0} />
                        <IconButton icon={<FaArrowDown />} colorScheme='blue' disabled={i===bouquetChannels.length-1} />
                      </ButtonGroup>
                    </Td>
                    <Td>{channel.channel_id}</Td>
                    <Td>{channel.title}</Td>
                    <Td>
                      <IconButton size='xs' colorScheme='red' icon={<FaTrash />} />
                    </Td>
                  </Tr>)
                }
              </Tbody>
            </Table>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Badge>#{bouquet.bouquet_id}</Badge>
          <Spacer />
          <ButtonGroup>
            <Button leftIcon={<FaTimes />} mr={1} onClick={closeModal}>Close</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export default BouquetsDetails