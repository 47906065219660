import {
  Stack
} from '@chakra-ui/react'
import ResellersTable from '../components/ResellersTable'

import { getResellers } from '../api'
import { useCallback, useEffect, useState } from 'react'

import ResellerDetails from '../components/ResellerDetails'
import Paginator from '../components/Paginator'

const Resellers = _ => {
  const [currentPage, setCurrentPage] = useState(0)
  const [resellersData, setResellersData] = useState({ resellers: [], totalResellers: currentPage })

  const refresh = useCallback(page => getResellers(page || currentPage).then(setResellersData), [currentPage])
  useEffect(_ => {
    refresh(currentPage)
  }, [currentPage, refresh])

  return <Stack spacing='4'>
    <ResellerDetails isCreate={true} refresh={refresh} />
    <ResellersTable resellers={resellersData.resellers} refresh={refresh} />
    <Paginator totalItems={resellersData.totalResellers} currentPage={currentPage} setCurrentPage={setCurrentPage} />
  </Stack>
}

export default Resellers
