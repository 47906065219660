import moment from 'moment'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonGroup
} from '@chakra-ui/react'

import ResellerDetails from './ResellerDetails'
import ResellerDelete from './ResellerDelete'
import UserTypeBadge from './UserTypeBadge'
import ActiveBadge from './ActiveBadge'

import { useRecoilState } from 'recoil'
import userState from '../userState'

const ResellersTable = ({ resellers, refresh }) => {
  const user = useRecoilState(userState)[0]
  return <Table variant='unstyled' size='sm'>
    <Thead>
      <Tr>
        <Th>Username</Th>
        <Th>Owner</Th>
        <Th>Status</Th>
        <Th>Expiration</Th>
        <Th>Credits</Th>
        <Th>&nbsp;</Th>
      </Tr>
    </Thead>
    <Tbody>
      {
        resellers.map(r => <Tr key={r.user_id}>
          <Td><UserTypeBadge user={r} /></Td>
          <Td><UserTypeBadge user={r.owner} /></Td>
          <Td><ActiveBadge active={r.active} /></Td>
          <Td>{r.expiration ? <>{moment(r.expiration).format('DD-MM-YYYY')}<br /><strong>{moment(r.expiration).fromNow()}</strong></> : 'Unlimited'}</Td>
          <Td>{r.credits}</Td>
          <Td>
            <ButtonGroup isAttached>
              <ResellerDetails initialReseller={r} isCreate={false} refresh={refresh} />
              { r.user_id !== user.user_id && <ResellerDelete reseller={r} refresh={refresh} />}
            </ButtonGroup>
          </Td>
        </Tr>)
      }
    </Tbody>
  </Table>
}

export default ResellersTable
