import moment from 'moment'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonGroup
} from '@chakra-ui/react'

import ActiveBadge from './ActiveBadge'
import UserTypeBadge from './UserTypeBadge'
import UserDetails from './UserDetails'
import UserDelete from './UserDelete'

const UsersTable = ({ subscriptions, users, refresh, bouquets }) => {
  return <Table variant='unstiled' size='sm'>
    <Thead>
      <Tr>
        <Th>Username</Th>
        <Th>Owner</Th>
        <Th>Status</Th>
        <Th>Expiration</Th>
        <Th>Online</Th>
        <Th>Connections</Th>
        <Th>&nbsp;</Th>
      </Tr>
    </Thead>
    <Tbody>
      {
        users.map(u => <Tr key={u.user_id}>
          <Td><UserTypeBadge user={u} /></Td>
          <Td><UserTypeBadge user={u.owner} /></Td>
          <Td><ActiveBadge active={u.active} /></Td>
          <Td>{u.expiration ? <>{moment(u.expiration).format('DD-MM-YYYY')}<br /><strong>{moment(u.expiration).fromNow()}</strong></> : 'Unlimited'}</Td>
          <Td>-</Td>
          <Td>?/{u.max_connections}</Td>
          <Td>
            <ButtonGroup isAttached>
              <UserDetails initialUser={u} refresh={refresh} subscriptions={subscriptions} bouquets={bouquets} />
              <UserDelete user={u} refresh={refresh} />
            </ButtonGroup>
          </Td>
        </Tr>
        )
      }
    </Tbody>
  </Table>
}

export default UsersTable
