import { ColorModeScript, theme, ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <RecoilRoot>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  </RecoilRoot>
);
