import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Grid,
  GridItem,
  Input,
  InputLeftAddon,
  InputGroup,
  ButtonGroup,
  Badge,
  Spacer,
  useToast,
} from '@chakra-ui/react';

import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from '@choc-ui/chakra-autocomplete';

import { useState } from 'react';
import { FaClipboardList, FaSave, FaTimes } from 'react-icons/fa';

import { saveSubscription, getBouquets } from '../api';

const SubscriptionDetails = ({ initialSubscription, refresh }) => {
  const toast = useToast();
  const [subscription, setSubscription] = useState(initialSubscription);
  const [bouquets, setBouquets] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = _ => {
    getBouquets().then(setBouquets);
    onOpen();
  };

  const closeModal = _ => {
    setSubscription(initialSubscription);
    onClose();
  };

  const updateBouquets = newBouquetNames => {
    const newBouquets = bouquets.filter(b => newBouquetNames.includes(b.name));
    setSubscription({ ...subscription, bouquets: newBouquets });
  };

  const save = async _ => {
    if (subscription.name.length === 0) {
      toast({
        title: 'Save failed',
        description: 'Name is required',
        status: 'error',
      });
      return;
    }
    await saveSubscription(subscription);
    toast({
      title: 'Save success',
      description: `${subscription.name} saved successfully!`,
      status: 'success',
    });
    refresh();
    onClose();
  };

  return (
    <>
      <Button
        onClick={openModal}
        size="sm"
        colorScheme="blue"
        leftIcon={<FaClipboardList />}
        style={{ width: 'fit-content' }}
      >
        Details
      </Button>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent style={{ maxWidth: '1024px' }}>
          <ModalHeader>Details: {initialSubscription.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="180px repeat(2, 1fr)" gap={2}>
              <GridItem colSpan={2}>
                <InputGroup>
                  <InputLeftAddon w="150px" children="Name" />
                  <Input
                    placeholder="Name"
                    value={subscription.name}
                    onChange={e =>
                      setSubscription({ ...subscription, name: e.target.value })
                    }
                  />
                </InputGroup>
              </GridItem>
              <GridItem colSpan={2}>
                <InputGroup>
                  <InputLeftAddon w="150px" children="Bouquets" />
                  <AutoComplete
                    openOnFocus
                    multiple
                    onChange={updateBouquets}
                    defaultValues={subscription.bouquets.map(b => b.name)}
                  >
                    <AutoCompleteInput>
                      {({ tags }) =>
                        tags.map((tag, tid) => (
                          <AutoCompleteTag
                            key={tid}
                            label={tag.label}
                            onRemove={tag.onRemove}
                          />
                        ))
                      }
                    </AutoCompleteInput>
                    <AutoCompleteList>
                      {bouquets.map((bouquet, x) => (
                        <AutoCompleteItem
                          key={x}
                          value={bouquet.name}
                          _selected={{ bg: 'whiteAlpha.50' }}
                          _focus={{ bg: 'whiteAlpha.100' }}
                        >
                          {bouquet.name}
                        </AutoCompleteItem>
                      ))}
                    </AutoCompleteList>
                  </AutoComplete>
                </InputGroup>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Badge>#{subscription.subscription_id}</Badge>
            <Spacer />
            <ButtonGroup>
              <Button leftIcon={<FaTimes />} mr={1} onClick={closeModal}>
                Close
              </Button>
              <Button rightIcon={<FaSave />} colorScheme="green" onClick={save}>
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubscriptionDetails;
