import { useState } from 'react'
import moment from 'moment'

import {
  Button,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Input,
  IconButton,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Switch,
  ButtonGroup,
} from '@chakra-ui/react'

import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag
} from '@choc-ui/chakra-autocomplete'

import { 
  FaClipboardList,
  FaSyncAlt,
  FaPlus,
  FaTimes,
  FaCoins,
} from 'react-icons/fa'
import utils from '../utils'

import { createUser, saveUser } from '../api'
import userState from '../userState'
import { useRecoilState } from 'recoil'

const getUserModel = _ => ({
  username: utils.getRandomString(),
  password: utils.getRandomString(),
  user_type: 0,
  credits: 0,
  max_connections: 1,
  expiration : null,
  bouquets: []
})

const UserDetails = ({ subscriptions, initialUser, isCreate, refresh, bouquets }) => {
  const loggedUser = useRecoilState(userState)[0]
  const toast = useToast()
  const [user, setUser] = useState(initialUser || getUserModel())
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const close = _ => {
    setUser(initialUser || getUserModel())
    onClose()
  }

  const setSubscription = sub => {
    setUser({
      ...user,
      expiration: moment().add(sub.duration, 'days').format('YYYY-MM-DD'),
      bouquets: sub.bouquets
    })
  }

  const updateBouquets = newBouquetNames => {
    const newBouquets = bouquets.filter(b => newBouquetNames.includes(b.name))
    setUser({ ...user, bouquets: newBouquets })
  }

  const save = async _ => {
    if (isCreate) {
      if (user.username.length === 0) {
        toast({
          title: 'Save failed',
          description: 'Username is required',
          status: 'error'
        })
        return
      }
      if (user.password.length === 0) {
        toast({
          title: 'Save failed',
          description: 'Password is required',
          status: 'error'
        })
        return
      }
      await createUser(user)
    } else {
      await saveUser(user)
    }
    toast({
      title: 'Save success',
      description: `${user.username} saved successfully!`,
      status: 'success'
    })
    refresh()
    onClose()
  }

  const cost = user.expiration ? Math.max(1, user.max_connections * Math.round(moment.duration(moment(user.expiration).diff(moment())).asMonths())) : 0
 
  return <>
  <Button onClick={onOpen}
    size={isCreate ? 'md' : 'sm'}
    colorScheme={isCreate ? 'green' : 'blue'}
    leftIcon={isCreate ? <FaPlus /> : <FaClipboardList />}
    style={{ width: 'fit-content' }}>
      {isCreate ? 'Create' : 'Details'}
  </Button>
  <Modal isOpen={isOpen} onClose={close} isCentered>
    <ModalOverlay />
    <ModalContent style={{maxWidth:'1024px'}}>
      <ModalHeader>{ isCreate ? 'Create' : 'Update'} user</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Grid templateColumns='repeat(1, 1fr)' gap={2}>
          <GridItem>
            <InputGroup>
              <InputLeftAddon w='150px' children='Username' />
              <Input placeholder='Username' value={user.username || ''} onChange={e => setUser({ ...user, username: e.target.value })} />
              <InputRightAddon children='Username'>
                <IconButton icon={<FaSyncAlt />} disabled={!isCreate} variant='ghost' onClick={_ => setUser({ ...user, username: utils.getRandomString() })} />
              </InputRightAddon>
            </InputGroup>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon w='150px' children='Password' />
              <Input placeholder='Password' value={user.password || ''} onChange={e => setUser({ ...user, password: e.target.value })} />
              <InputRightAddon children='Password'>
                <IconButton icon={<FaSyncAlt />} variant='ghost' onClick={_ => setUser({ ...user, password: utils.getRandomString() })} />
              </InputRightAddon>
            </InputGroup>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon w='150px' children='Max Connections' />
              <Input type='number' value={user.max_connections || 1} onChange={e => setUser({ ...user, max_connections: 1 * e.target.value })} />
            </InputGroup>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon w='150px' children='Subscription' />
              <Select defaultValue='0' onChange={e => setSubscription(subscriptions.find(s => s.packet_id === 1 * e.target.value))}>
                <option value='0' disabled>Select Subscription</option>
                {
                  subscriptions.map(subscription => <option key={subscription.packet_id} value={subscription.packet_id}>{subscription.name}</option>)
                }
              </Select>
            </InputGroup>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon w='150px' children='Expiration' />
              <Input type='date' disabled={user.expiration === null}
                value={moment(user.expiration || undefined).format('yyyy-MM-DD')}
                onChange={e => setUser({ ...user, expiration: e.target.checked ? null : moment().format('yyyy-MM-DD') })}
                min={moment().format('yyyy-MM-DD')} />
              <InputRightAddon>
                <FormControl display='flex' alignItems='center'>
                  <FormLabel htmlFor='expiration' mb='0'>
                    Unlimited
                  </FormLabel>
                  <Switch id='expiration' isChecked={user.expiration === null} onChange={e => setUser({ ...user, expiration: e.target.checked ? null : moment().format('yyyy-MM-DD') })} />
                </FormControl>                 
              </InputRightAddon>
            </InputGroup>
          </GridItem>
          <GridItem>            
            <Stack direction='row' spacing='4'>
              <Button variant='outline' onClick={_ => setUser({ ...user, expiration: moment().add(1, 'month').format('yyyy-MM-DD') })}>1 Month</Button>
              <Button variant='outline' onClick={_ => setUser({ ...user, expiration: moment().add(3, 'months').format('yyyy-MM-DD') })}>3 Months</Button>
              <Button variant='outline' onClick={_ => setUser({ ...user, expiration: moment().add(6, 'months').format('yyyy-MM-DD') })}>6 Months</Button>
              <Button variant='outline' onClick={_ => setUser({ ...user, expiration: moment().add(1, 'year').format('yyyy-MM-DD') })}>1 Year</Button>
            </Stack>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon w='150px' children='Bouquets' />
              <AutoComplete openOnFocus multiple onChange={updateBouquets} values={user.bouquets.map(b => b.name)}>
                <AutoCompleteInput>
                  {
                    ({ tags }) => tags.map((tag, tid) => <AutoCompleteTag key={tid} label={tag.label} onRemove={tag.onRemove} />)
                  }
                </AutoCompleteInput>
                <AutoCompleteList>
                  {
                    bouquets.map((bouquet, x) => (
                      <AutoCompleteItem key={x} value={bouquet.name} _selected={{ bg: 'whiteAlpha.50' }} _focus={{ bg: 'whiteAlpha.100' }}>
                        {bouquet.name}
                      </AutoCompleteItem>
                    ))
                  }
                </AutoCompleteList>
              </AutoComplete>
            </InputGroup>
          </GridItem>
          <GridItem>
            <Stack>
              <Text fontWeight='bold'>
                Expiration { user.expiration ? moment(user.expiration).fromNow() : 'Unlimited' }
              </Text>
              <Text>
                After purchase: <strong>{loggedUser.credits - cost} credits</strong>
              </Text>
            </Stack>
          </GridItem>
        </Grid>            
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button leftIcon={<FaTimes />} mr={1} onClick={close}>Close</Button>
          <Button rightIcon={<FaCoins />} colorScheme='green' onClick={save}>
            {isCreate ? (user.expiration ? `Purchase at ${cost} credit${cost === 1 ? '' : 's'}` : 'Purchase Free') : 'Update'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
</>
}

export default UserDetails
