import { useState } from 'react'

import { Table, Thead, Tbody, Tr, Th, Td, Button, ButtonGroup, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'

import { FaPlus, FaTimes, FaSave, FaSearch } from 'react-icons/fa'

import BouquetsDetails from './BouquetsDetails'

function BouquetsTable({ bouquets, refresh }) {
  const [isAdd, setAdd] = useState(false)
  const [newBouquet, setNewBouquet] = useState('')
  const [search, setSearch] = useState('')

  const save = async _ => {
    if (newBouquet.length > 0) {
      /* await createBouquet(newBouquet) */
      setNewBouquet('')
      setAdd(false)
      refresh()
    }
  }

  const add = _ => setAdd(true)

  const cancelAdd = _ => {
    setAdd(false)
    setNewBouquet('')
  }

  return <>
  <Table variant='unstiled' size='sm'>
    <Thead>
    <Tr>
        <Td></Td>
        <Td>
          <InputGroup>
            <InputLeftElement pointerEvents='none' children={<FaSearch />} />
            <Input placeholder='Search...' variant='filled' value={search} onChange={e => setSearch(e.target.value)} />
            <InputRightElement>
              <Button size='sm' disabled={search.length === 0} onClick={_ => setSearch('')}><FaTimes /></Button>
            </InputRightElement>
          </InputGroup>
        </Td>
        <Td></Td>
      </Tr>
      <Tr>
        <Th>ID</Th>
        <Th>Name</Th>
        <Th>&nbsp;</Th>
      </Tr>
    </Thead>
    <Tbody>
      {
        bouquets.map(bouquet => {
          return <Tr key={bouquet.bouquet_id}>
            <Td>{bouquet.bouquet_id}</Td>
            <Td>{bouquet.name}</Td>
            <Td>
              <BouquetsDetails bouquet={bouquet} />
            </Td>
          </Tr>
        })
      }
      <Tr>
        {
          isAdd
          ? <>
            <Td></Td>
            <Td>
              <Input size='sm' variant='filled' value={newBouquet} onChange={e => setNewBouquet(e.target.value)} />
            </Td>
            <Td>
              <ButtonGroup isAttached>
                <Button size='sm' colorScheme='green' onClick={save}><FaSave /></Button>
                <Button size='sm' colorScheme='red' onClick={cancelAdd}><FaTimes /></Button>
              </ButtonGroup>
            </Td>
          </>
          : <>
            <Td></Td>
            <Td></Td>
            <Td>
              <Button size='sm' onClick={add}><FaPlus /></Button>
            </Td>
          </>
        }
      </Tr>
    </Tbody>
  </Table>
  </>
}

export default BouquetsTable
